import LoginProviderCallbackScreen from 'customPages/loginProviderCallback';
import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import history from 'technical/history';
import {
  AllInboxOutlined,
  BallotOutlined,
  BusinessCenterOutlined,
  BusinessOutlined,
  ContactMailOutlined,
  DateRangeOutlined,
  InsertInvitationOutlined,
  PersonOutlined,
  PlaylistAddCheckOutlined,
  PublicOutlined,
  SchoolOutlined,
  SupervisorAccountOutlined,
} from '@material-ui/icons';
import {
  UserEdit,
  UserList,
  OperatorList,
  UserCreate,
  UserShow,
} from './business/user/resource';
import {
  EstablishmentCreate,
  EstablishmentEdit,
  EstablishmentList,
  EstablihsmentShow,
} from './business/establishment/resource';
import LoginPage from './customPages/login';
import MyLayout from './customPages/layout';
import dataProvider from './technical/api/dataProvider';
import authProvider from './technical/auth/authProvider';
import i18nProvider from './translation';
import {
  EpisodeCreate,
  EpisodeEdit,
  EpisodeList,
  EpisodeShow,
} from './business/episode/resource';
import {
  ClassCreate,
  ClassEdit,
  ClassList,
  ClassShow,
} from './business/class/resource';
import {
  TrainingCreate,
  TrainingEdit,
  AllTrainingList,
  TrainingShow,
  TrainingToPlanList,
} from './business/training/resource';
import {
  RegionCreate,
  RegionEdit,
  RegionList,
} from './business/region/resource';
import {
  SiteCreate,
  SiteEdit,
  SiteList,
  SiteShow,
} from './business/site/resource';
import {
  ContactCreate,
  ContactEdit,
  ContactList,
  ContactShow,
} from './business/contact/resource';
import {
  getRoles,
  isOperator,
  shouldSeeAllRegionsRoles,
} from './technical/auth/services';
import { SpeakerEvaluationList } from './business/speakerEvaluation/resource';
import createMyTheme from './customPages/Theme/createMyTheme';
import DeactivatePage from './customPages/deactivate';
import RegionExport from './customPages/regionExport';

const roles = getRoles();
const myTheme = createMyTheme({
  palette: {
    secondary: {
      main: '#4EC7E4',
    },
  },
  sidebar: {
    width: 250,
  },
  overrides: {
    MuiMenuItem: {
      root: {
        whiteSpace: 'normal',
      },
    },
    MuiChip: {
      root: {
        height: '16px',
        backgroundColor: '#4EC7E4',
      },
    },
  },
});

function App() {
  return (
    <div style={{ position: 'relative' }}>
      <Admin
        history={history}
        layout={MyLayout}
        theme={myTheme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        loginPage={LoginPage}
        customRoutes={[
          <Route
            exact
            path="/login/callback"
            component={LoginProviderCallbackScreen}
            noLayout
          />,
          <Route
            exact
            path="/registration-3SY2wWahkbFWST2q"
            component={() => <LoginPage signupMode />}
            noLayout
          />,
          <Route
            exact
            path="/deactivate"
            component={DeactivatePage}
            noLayout
          />,
          <Route exact path="/exports" component={RegionExport} />,
        ]}
      >
        {() => [
          <Resource
            name="class"
            list={ClassList}
            options={{ label: "Tous les packs d'épisodes" }}
            edit={ClassEdit}
            create={ClassCreate}
            show={ClassShow}
            icon={AllInboxOutlined}
          />,
          <Resource
            name="episode"
            list={EpisodeList}
            options={{ label: 'Tous les épisodes' }}
            show={EpisodeShow}
            edit={EpisodeEdit}
            create={EpisodeCreate}
            icon={InsertInvitationOutlined}
          />,
          <Resource
            name="episode_to_fill"
            list={EpisodeList}
            options={{ label: 'Episodes à combler' }}
            edit={EpisodeEdit}
            show={EpisodeShow}
            icon={BallotOutlined}
          />,
          <Resource
            name="episode_to_plan"
            list={EpisodeList}
            options={{ label: 'Episodes à planifier' }}
            edit={EpisodeEdit}
            show={EpisodeShow}
            icon={DateRangeOutlined}
          />,
          <Resource
            name="animator"
            list={UserList}
            options={{ label: 'Tous les intervenants' }}
            edit={UserEdit}
            show={UserShow}
            icon={PersonOutlined}
          />,
          <Resource
            name="training"
            show={TrainingShow}
            list={AllTrainingList}
            options={{ label: 'Toutes les formations' }}
            edit={TrainingEdit}
            create={TrainingCreate}
            icon={BusinessCenterOutlined}
          />,
          <Resource
            name="training_to_validate"
            show={TrainingShow}
            list={AllTrainingList}
            edit={TrainingEdit}
            options={{ label: 'Formations à valider' }}
            icon={PlaylistAddCheckOutlined}
          />,
          <Resource
            name="training_to_plan"
            show={TrainingShow}
            list={TrainingToPlanList}
            options={{ label: 'Formations à planifier' }}
            edit={TrainingEdit}
            icon={DateRangeOutlined}
          />,
          <Resource
            name="site"
            list={SiteList}
            edit={SiteEdit}
            create={SiteCreate}
            show={SiteShow}
            options={{ label: 'Tous les sites' }}
            icon={BusinessOutlined}
          />,
          <Resource
            name="establishment"
            list={EstablishmentList}
            options={{ label: 'Tous les établissements' }}
            edit={EstablishmentEdit}
            create={EstablishmentCreate}
            show={EstablihsmentShow}
            icon={SchoolOutlined}
          />,
          <Resource
            name="contact"
            list={ContactList}
            edit={ContactEdit}
            create={ContactCreate}
            show={ContactShow}
            options={{ label: 'Tous les contacts' }}
            icon={ContactMailOutlined}
          />,
          shouldSeeAllRegionsRoles(roles) || isOperator(roles) ? (
            <Resource
              name="operator"
              list={OperatorList}
              options={{
                label: shouldSeeAllRegionsRoles(roles)
                  ? 'Tous les opérateurs et administrateurs'
                  : 'Tous les gestionnaires régionaux',
              }}
              edit={UserEdit}
              show={UserShow}
              icon={SupervisorAccountOutlined}
              create={UserCreate}
            />
          ) : null,
          <Resource
            name="region"
            list={RegionList}
            edit={RegionEdit}
            create={RegionCreate}
            options={{ label: 'Toutes les régions' }}
            icon={PublicOutlined}
          />,
          <Resource name="user_training" />,
          <Resource name="user_region_animation" />,
          <Resource name="user_formation_status" />,
          <Resource name="speakerEvaluation" list={SpeakerEvaluationList} />,
        ]}
      </Admin>
      {process.env.REACT_APP_DISPLAY_VERSION === 'true' && (
        <div
          style={{
            right: 0,
            bottom: 0,
            fontSize: '12px',
            zIndex: 100,
            padding: 3,
            backgroundColor: '#fafafa',
            position: 'absolute',
          }}
        >
          v2.3.7
        </div>
      )}
    </div>
  );
}

export default App;
