import React, { useEffect } from 'react';
import { useNotify, TextInput } from 'react-admin';
import { useForm } from 'react-final-form';

const placesRequiredFields = ['address_components', 'geometry.location'];

interface GeoAddress {
  name: string;
  input: string;
  city: string;
  zipCode: string;
  country: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
}

const emptyGeoAddress: GeoAddress = {
  name: '',
  input: '',
  city: '',
  zipCode: '',
  country: '',
  coordinates: {
    latitude: 0,
    longitude: 0,
  },
};

export default function GooglePlacesInput() {
  const form = useForm();
  const notify = useNotify();

  const removeDistrict = (city: string) => {
    const parts = city.split(' ');
    if (parts[parts.length - 1] === 'Arrondissement') {
      // Remove number of district + "district"
      parts.splice(parts.length - 2, 2);
      const newCityName = parts.join(' ');
      return newCityName;
    }
    return city;
  };

  const inputRef: HTMLInputElement | null = document.querySelector(
    '#address-google',
  );

  useEffect(() => {
    if (inputRef) {
      const autocomplete = new google.maps.places.Autocomplete(inputRef, {
        componentRestrictions: { country: ['fr', 're', 'gp', 'mq', 'gf'] },
        fields: placesRequiredFields,
        types: ['address'],
      });

      const fillInAddress = () => {
        const place = autocomplete.getPlace();
        const { address_components: addressComponent, geometry } = place;

        const placeGeoAddress = { ...emptyGeoAddress };

        // Fields here should match the array `placesRequiredFields`
        if (!addressComponent || !geometry || !geometry.location) {
          notify(
            "L'adresse renvoyée par le service externe n'est pas valide",
            'warning',
          );
          return;
        }

        addressComponent.forEach(placeElement => {
          const { types, long_name: longName } = placeElement;

          if (types.includes('street_number')) {
            placeGeoAddress.name = `${longName}`;
          }

          if (types.includes('route')) {
            placeGeoAddress.name = `${placeGeoAddress.name} ${longName}`;
          }

          if (types.includes('locality')) {
            placeGeoAddress.city = longName;
          }

          if (types.includes('postal_code')) {
            placeGeoAddress.zipCode = longName;
          }

          if (types.includes('country')) {
            placeGeoAddress.country = longName;
          }
        });

        if (placeGeoAddress.name === '') {
          form.change('address', null);
        }

        if (placeGeoAddress.zipCode === '') {
          form.change('postalCode', null);
        }

        if (placeGeoAddress.city === '') {
          form.change('city', null);
        }

        placeGeoAddress.coordinates = {
          latitude: geometry.location.lat(),
          longitude: geometry.location.lng(),
        };

        form.change('address', placeGeoAddress.name);
        form.change('postalCode', placeGeoAddress.zipCode);
        form.change(
          'city',
          placeGeoAddress.city
            ? removeDistrict(placeGeoAddress.city)
            : placeGeoAddress.city,
        );
        form.change(
          'location',
          JSON.stringify({
            type: 'Point',
            coordinates: [
              placeGeoAddress.coordinates.longitude,
              placeGeoAddress.coordinates.latitude,
            ],
          }),
        );
      };

      autocomplete.addListener('place_changed', fillInAddress);
    }
  }, [form, inputRef, notify]);

  const searchValidation = (_value, allValues) => {
    let errors;
    if (allValues.address === '') {
      errors =
        "L'adresse indiquée n'est pas valide. Veuillez rentrer une adresse proche pour permettre de géolocaliser l'établissement et remplir l'adresse exacte dans le champ 'Adresse secondaire'";
    }
    if (allValues.city === '') {
      errors =
        "L'adresse indiquée n'est pas valide car elle ne présente pas de Ville. Veuillez rentrer une adresse proche pour permettre de géolocaliser l'établissement et remplir l'adresse exacte dans le champ 'Adresse secondaire'";
    }
    return errors;
  };

  return (
    <TextInput
      type="search"
      id="address-google"
      name="address-google"
      placeholder="Adresse"
      validate={searchValidation}
      fullWidth
    />
  );
}
